window.addEventListener("load", function () {
  let video = document.querySelector(".video-full");
  video.play();
});

window.addEventListener("load", function () {
  const words = document.querySelectorAll(".animation-word");
  let currentIndex = 0;

  function showNextWord() {
    words[currentIndex].classList.remove("active");
    currentIndex = (currentIndex + 1) % words.length;
    words[currentIndex].classList.add("active");
  }

  // Inicialmente mostrar a primeira palavra
  words[currentIndex].classList.add("active");

  // Alternar a palavra a cada 2 segundos (2000 milissegundos)
  setInterval(showNextWord, 2000);
});

var elements = document.querySelectorAll(".fullBanner");

// Verifica se existe um segundo elemento
if (elements.length > 1) {
  let segundoElemento = elements[1];
  let mensagem = document.querySelectorAll(".fullBanner__content");
  segundoElemento.style.marginTop = "0";
  mensagem[1].style.display = "none";
}
